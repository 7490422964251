import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KRTN5RP'
}

export default function GTM(){
  return (
    TagManager.initialize(tagManagerArgs)
  );
}

