import React from "react";
import { NavLink } from "./NavbarElements";

const Header = () => {
	return (
		<header>
			<nav class="menu-main">
				<NavLink className="menu-item" to="/" activeStyle>Home</NavLink>
				<NavLink className="menu-item" to="/about" activeStyle>About</NavLink>
				<NavLink className="menu-item" to="/contact" activeStyle> Contact Us</NavLink>
				{/* <NavLink to="/blogs" activeStyle>Blogs</NavLink> */}
				{/* <NavLink to="/sign-up" activeStyle>Sign Up</NavLink> */}
			</nav>
		</header>
	);
};

export default Header;
