  
const FrontPage = () => {
	return (
	  <div>
		  <h1>FrontPage</h1>
			  Page Content!
	  </div>
	);
  };
	
  export default FrontPage;
  