import React from "react";

/* eslint-disable */
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
/* eslint-enable */
import {FrontPage, About, Contact} from "../../pages";

export const PageContent = () => {
	return (
	<main >
		<Routes className="main">
			<Route className="main-entry" path='/'			element={<FrontPage />} exact />
			<Route className="main-entry" path='/about' 	element={<About/>} />
			<Route className="main-entry" path='/contact'	element={<Contact/>} />
		</Routes>
	</main>
	);
};

export default PageContent;