import React from "react";

export const Footer = () => {
	return (
	<footer>
		<div>This is the Footer</div>
	</footer>
	);
};

export default Footer;