
import React from 'react';
import "./assets/styles.scss";

import Header from './components/Header';
import PageContent from './components/pagecontent';
import Footer from './components/Footer';


import GoogleTagManager from './components/GoogleTagManager';
import { BrowserRouter as Router} from 'react-router-dom';




GoogleTagManager();

function App() {
  return (
    <Router>
      <Header />
      <PageContent />
      <Footer />
    </Router>
  );
}

export default App;
